import axios from 'axios';

const fetch = axios.create({
  baseURL: '/api',
  headers: {
    'x-csrf-token': document.querySelector('meta[name="csrf-token"]').content,
  }
});

export default fetch;
