import React, { PureComponent } from 'react';
import { Table, Button, Label, Select } from 'semantic-ui-react';
import DonationAmounts from '../Donation/DonationAmounts';
import Api from '../lib/api';
import Donation from '../../models/Donation';

const numberFormat = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });

class DonationSubscriptionForm extends PureComponent {
  constructor(props) {
    super(props);

    const { donationSubscription, texts } = props;

    this.state = {
      isLoading: false,
      amount: donationSubscription.amount,
      inputAmount: '',
      billingCycle: {
        value: donationSubscription.billingCycle
      }
    };

    this.braintreeCallback = this.braintreeCallback.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { braintreeAuthorization, isOneTime } = this.props;

    if (this.props.edit !== prevProps.edit && this.props.edit) {
      const { informationAmount } = this.props;
      const amount = 0.99;
      const dropinContainer = document.getElementById("dropin-container");
      dropinContainer.innerHTML = "";

      braintree.dropin.create({
        vaultManager: true,
        integrationType: 'drop-in',
        authorization: braintreeAuthorization,
        selector: '#dropin-container',
        applePay: {
          displayName: 'INTO GLOBAL MINISTRY',
            paymentRequest: {
            total: {
              label: isOneTime ? 'INTO Global Ministry - Recurring Donation' : 'INTO Global Ministry - One Time Donation',
              amount
            },
          }
        },
        locale: window.navigator.language.replace('-', '_')
      }, this.braintreeCallback.bind(this));
    }
  }

  requestPaymentMethod() {
    const { donationSubscription } = this.props;
    const { braintreeInstance } = this.state;

    if (braintreeInstance) {
      return new Promise((resolve, reject) => {
        this.state.braintreeInstance.requestPaymentMethod((err, payload) => {
          if (payload) {
            Api.post('/users/add_payment_method', {
              nonce: payload.nonce,
              donation_subscription_id: donationSubscription.id
            }).then(resolve).catch(reject);
          } else {
            resolve();
          }
        });
      });
    }
  }

  braintreeCallback(createErr, instance) {
    this.setState({
      braintreeInstance: instance
    });
  }

  setInformationAmount(infoAmt) {
    this.setState(infoAmt);
  }

  addDonation() {
    const { donationSubscription, isOneTime } = this.props;
    const { amount } = this.state;

    if (isOneTime) {
        const donation = Donation.build({ amount, donationSubscriptionId: donationSubscription.id });
        return donation.save();
    }

    return Promise.resolve();
  }

  save() {
    const { donationSubscription, isOneTime, reload, onExit } = this.props;
    const { amount, billingCycle } = this.state;

    this.setState({ isLoading: true });

    this.requestPaymentMethod().then(() => {
      donationSubscription.amount = amount;
      donationSubscription.billingCycle = billingCycle.value;

      this.addDonation().then(() => {
        donationSubscription.save().then(() => {
          reload();
          onExit();
        }).finally(() => {
          this.setState({
            isLoading: false
          });
        });
      });
    }).finally(() => {
      this.setState({ isLoading: false });
    });
  }

  render() {
    const { donationSubscription, braintreePaymentMethod, texts, isOneTime, edit } = this.props;

    return (!isOneTime || edit) &&(
      <Table definition className="no-margin border-none">
        <Table.Body>
          <Table.Row>
            <Table.Cell width={4}>{texts.donationAmount}</Table.Cell>
            <Table.Cell>
              {edit ? (
                <DonationAmounts
                  texts={texts}
                  amount={this.state.amount}
                  inputAmount={this.state.inputAmount}
                  setInformationAmount={(infoAmt) => this.setInformationAmount(infoAmt)}
                />
              ) : (
                numberFormat.format(donationSubscription.amount)
              )}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell width={4}>
              {texts.donationCycle}
            </Table.Cell>
            <Table.Cell>
              {texts.billingCycles[donationSubscription.billingCycle]}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell width={4}>{texts.paymentMethod}</Table.Cell>
            <Table.Cell>
              {edit ? (
                <section id="dropin-container"></section>
              ) : (
                <div className="flex items-center">
                  <img src={braintreePaymentMethod.imageUrl} height="25" />
                  <div className="fs-1 ml-5">
                    <Label size="small">{braintreePaymentMethod.last4}</Label>
                  </div>
                </div>
              )}
            </Table.Cell>
          </Table.Row>

          {edit && (
            <Table.Row>
              <Table.Cell colSpan="2">
                <Button
                  color="green"
                  className="w-100"
                  loading={this.state.isLoading}
                  disabled={this.state.isLoading}
                  onClick={() => this.save()}
                >
                  {isOneTime ? texts.donate : texts.save}
                </Button>
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
    );
  }
}

export default DonationSubscriptionForm;
