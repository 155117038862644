import { createResourceLibrary } from 'active-resource';

export default createResourceLibrary(
  '/api/',
  {
    headers: {
      'x-csrf-token': document.querySelector('meta[name="csrf-token"]').content
    }
  }
);
