import React, { Fragment, Component } from 'react';
import StepWizard from 'react-step-wizard';
import { Button, Card, Grid, Icon, Input, Label, Form, Select } from 'semantic-ui-react'
import DonationInformation from './DonationInformation';
import DonationNav from './DonationNav';
import DonationPaymentMethod from './DonationPaymentMethod';

class Donation extends Component {
  constructor(props) {
    super(props);

    this.setInformationAmount = this.setInformationAmount.bind(this);
    this.setPaymentMethod = this.setPaymentMethod.bind(this);

    this.state = {
      informationAmount: {
        selectedAmount: null,
        billingCycle: props.texts.billingCycles[0],
        inputAmount: '',
        email: '',
        firstName: '',
        lastName: '',
        donationPlanId: props.recurringDonationPlanId
      },
      paymentMethod: {

      }
    };
  }

  setInformationAmount(infoAmt) {
    this.setState(({ informationAmount }) => {
      const { recurringDonationPlanId, oneTimeDonationPlanId, } = this.props;
      const newInformationAmount = {
        ...informationAmount,
        ...infoAmt
      };

      if (newInformationAmount.billingCycle.value === 0) {
        newInformationAmount.donationPlanId = oneTimeDonationPlanId;
      } else if (newInformationAmount.billingCycle.value === 1) {
        newInformationAmount.donationPlanId = recurringDonationPlanId;
      }

      console.log(this.props);

      return {
        informationAmount: newInformationAmount
      };
    });
  }

  setPaymentMethod(paymentMethodUpdate) {
    this.setState(({ paymentMethod }) => ({
      paymentMethod: {
        ...paymentMethod,
        ...paymentMethodUpdate
      }
    }));
  }

  render() {
    const { texts, language, recurringDonationPlanId, oneTimeDonationPlanId, braintreeAuthorization } = this.props;

    console.log(this.state.informationAmount);

    return (
      <Form>
        <Card fluid className="box overflow-hidden">
          <StepWizard nav={<DonationNav texts={texts} />} isLazyMount={true}>
            <DonationInformation
              texts={texts}
              language={language}
              informationAmount={this.state.informationAmount}
              setInformationAmount={this.setInformationAmount}
            />
            <DonationPaymentMethod
              texts={texts}
              language={language}
              informationAmount={this.state.informationAmount}
              paymentMethod={this.state.paymentMethod}
              setPaymentMethod={this.setPaymentMethod}
              braintreeAuthorization={braintreeAuthorization}
            />
          </StepWizard>
        </Card>
      </Form>
    );
  }
}

export default Donation;
