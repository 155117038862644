import React, { Component } from 'react';
import { Button, Card, Table, Message } from 'semantic-ui-react'
import Api from '../../lib/api';

class DonationPaymentMethod extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: null,
      isDisabled: true
    };
    this.braintreeCallback = this.braintreeCallback.bind(this);
  }

  braintreeCallback(createErr, instance) {
    console.error(createErr);

    this.setState({
      braintreeInstance: instance
    });

    console.log(instance);
  }

  componentDidMount() {
    console.log('component')
    const { informationAmount, braintreeAuthorization } = this.props;
    const { amount, billingCycle } = informationAmount;

    const dropinContainer = document.getElementById("dropin-container");
    dropinContainer.innerHTML = "";

    braintree.dropin.create({
      authorization: braintreeAuthorization,
      selector: '#dropin-container',
      applePay: {
        displayName: 'INTO GLOBAL MINISTRY',
          paymentRequest: {
          total: {
            label: billingCycle === 1 ? 'INTO Global Ministry - Recurring Donation' : 'INTO Global Ministry - One Time Donation',
            amount
          },
        }
      },
      locale: window.navigator.language.replace('-', '_'),
      onPaymentMethodReceived: (obj) => {
        console.log(obj);
      },
    }, this.braintreeCallback.bind(this));
  }

  createUserAccount() {
    const { informationAmount } = this.props;
    const { email, firstName, lastName, billingCycle } = informationAmount;

    return Api.post('/user_accounts/create_user_account', {
      user_account: {
        email,
        first_name: firstName,
        last_name: lastName
      }
    });
  }

  signUp() {
    const { informationAmount } = this.props;
    const { email, firstName, lastName, billingCycle } = informationAmount;

    this.setState({ errors: null, isLoading: true });

    Api.post('/users/registrations', {
      user: {
        email,
        first_name: firstName,
        last_name: lastName
      }
    }).then(() => {
      this.requestPaymentMethod();
    }).catch((response) => {
      const errors = Object.keys(response.response.data).map((key) => `${key}: ${response.response.data[key]}`);
      this.setState({ errors });
    }).finally(() => this.setState({ isLoading: false }));
  }

  requestPaymentMethod() {
    const { informationAmount } = this.props;
    const { amount, billingCycle } = informationAmount;

    console.log(informationAmount);

    this.state.braintreeInstance.requestPaymentMethod((err, payload) => {
      Api.post('/users/create_recurring_donation', {
        nonce: payload.nonce,
        billing_cycle: billingCycle.value,
        donation_plan_id: informationAmount.donationPlanId,
        amount,
      }).then(() => {
        window.location.href = "/manage";
      }).catch((response) => {
        const errors = Object.keys(response.response.data).map((key) => `${key}: ${response.response.data[key]}`);
        this.setState({ errors });
      });
    });
  }

  render() {
    const { texts, informationAmount } = this.props;

    return (
      <Card fluid className="no-shadow">
        <Card.Content>
          <div className="flex flex-column donation-payment-method">
            <Table singleLine>
              <Table.Body>
                <Table.Row>
                  <Table.Cell><strong>{texts.billingCycleLabel}</strong></Table.Cell>
                  <Table.Cell>{informationAmount.billingCycle.text}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell><strong>{texts.amountLabel}</strong></Table.Cell>
                  <Table.Cell>${informationAmount.amount}</Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>

            <div className="mt-5">
              <div id="dropin-container"></div>
            </div>
          </div>
        </Card.Content>

        {this.state.errors && (
          <div class="p-15">
            <Message
              error
              header="Error"
              list={this.state.errors}
            />
          </div>
        )}

        <Button loading={this.state.isLoading} disabled={this.state.isLoading} size="big" color="green" type="button" onClick={() => this.signUp()}>
          {texts.stepsButton[1]}
        </Button>
      </Card>
    );
  }
}

export default DonationPaymentMethod;
