import Store from './Store';

class DonationPlan extends Store.Base {
  static className = 'DonationPlan';
  static define() {
    this.attributes('isActive', 'name');
  }
}

export default Store.createResource(DonationPlan);
