import React, { Component } from 'react';
import { Button, Card, Grid, Icon, Input, Label, Form, Select } from 'semantic-ui-react'
import DonationAmounts from './DonationAmounts';

class DonationInformation extends Component {
  state = {
    amount: null,
    inputAmount: '',
    email: '',
    firstName: '',
    lastName: ''
  };

  get buttonDisabled() {
    const { amount, email, firstName, lastName } = this.props.informationAmount;

    return !amount || !email || !firstName || !lastName;
  }

  render() {
    const { texts, informationAmount, setInformationAmount, language } = this.props;
    const { amount, inputAmount, email, firstName, lastName } = informationAmount;

    return (
      <Card fluid className="no-shadow">
        <Card.Content>
          <Form.Group widths="equal">
            <Form.Field>
              <label>{texts.billingCycleLabel}</label>
              <Select
                value={informationAmount.billingCycle.value}
                options={texts.billingCycles}
                onChange={(e, option) => {
                  setInformationAmount({ billingCycle: option.options.find(opt => opt.value === option.value) });
                }}
              />
            </Form.Field>
          </Form.Group>
        </Card.Content>

        <Card.Content>
          <div className="p-10">
            <h4 className="border-bottom pb-15 gray-600">
              <Icon name='user' />
              {texts.donorInformation}
            </h4>
            {language === "ko" ? (
              <Form.Group widths='equal'>
                <Form.Field>
                  <label>{texts.donorLastName}</label>
                  <input
                    placeholder={texts.donorLastName}
                    value={lastName}
                    name="lastName"
                    onChange={(e) => {
                      setInformationAmount({ lastName: e.currentTarget.value });
                    }}
                  />
                </Form.Field>
                <Form.Field>
                  <label>{texts.donorFirstName}</label>
                  <input
                    placeholder={texts.donorFirstName}
                    value={firstName}
                    name="firstName"
                    onChange={(e) => {
                      setInformationAmount({ firstName: e.currentTarget.value });
                    }}
                  />
                </Form.Field>
              </Form.Group>
            ) : (
              <Form.Group widths='equal'>
                <Form.Field>
                  <label>{texts.donorFirstName}</label>
                  <input
                    placeholder={texts.donorFirstName}
                    value={firstName}
                    name="firstName"
                    onChange={(e) => {
                      setInformationAmount({ firstName: e.currentTarget.value });
                    }}
                  />
                </Form.Field>
                <Form.Field>
                  <label>{texts.donorLastName}</label>
                  <input
                    placeholder={texts.donorLastName}
                    value={lastName}
                    name="lastName"
                    onChange={(e) => {
                      setInformationAmount({ lastName: e.currentTarget.value });
                    }}
                  />
                </Form.Field>
              </Form.Group>
            )}

            <Form.Field>
              <label>{texts.donorEmail}</label>
              <input
                placeholder={texts.donorEmailPlaceholder}
                value={email}
                onChange={(e) => {
                  setInformationAmount({ email: e.currentTarget.value });
                }}
              />
            </Form.Field>


            <h4 className="border-bottom pt-20 pb-15 gray-600">
              <Icon name='dollar' />
              {texts.selectAmount}
            </h4>
            <DonationAmounts
              texts={texts}
              amount={amount}
              inputAmount={inputAmount}
              setInformationAmount={setInformationAmount}
            />

          </div>
        </Card.Content>

        <Button disabled={this.buttonDisabled} size="big" color="green" type="button" onClick={() => this.props.nextStep()}>
          {texts.stepsButton[0]}
        </Button>
      </Card>
    );
  }
}

export default DonationInformation;
