import Store from './Store';

class BraintreePaymentMethod extends Store.Base {
  static className = 'BraintreePaymentMethod';
  static define() {
    this.attributes('cardType', 'expirationMonth', 'expirationYear', 'imageUrl', 'last4', 'isActive', 'isDefault');
  }
}

export default Store.createResource(BraintreePaymentMethod);
