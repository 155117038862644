import Store from './Store';

class DonationSubscription extends Store.Base {
  static className = 'DonationSubscription';
  static define() {
    this.belongsTo('user');
    this.belongsTo('donationPlan');
    this.belongsTo('braintreePaymentMethod');
    this.hasMany('donations');

    this.attributes('amount', 'billingCycle', 'isActive');
  }
}

export default Store.createResource(DonationSubscription);
