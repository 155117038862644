import Store from './Store';

class Donation extends Store.Base {
  static className = 'Donation';
  static define() {
    this.belongsTo('donationSubscription');
    this.attributes('amount', 'status', 'cardType', 'imageUrl', 'last4', 'donationSubscriptionId', 'createdAt');
  }
}

export default Store.createResource(Donation);
