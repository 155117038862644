import React, { Component } from 'react';
import Spinner from './Spinner';

class Fetch extends Component {
  state = {
    isLoading: true
  };

  constructor(props) {
    super(props);
    this.reload = this.reload.bind(this);
  }

  componentDidMount() {
    this.props.proxy.load().then((data) => {
      this.setState({ data });
    });
  }

  reload() {
    const { data } = this.state;

    return this.props.proxy.load().then((newData) => {
      this.setState({ data: newData });
    });
  }

  render() {
    const { children } = this.props;
    const { data } = this.state;

    return data ? (
      children(data, this.reload)
    ) : (
      <div className="flex items-center justify-center w-100 h-100">
        <Spinner />
      </div>
    );
  }
}

export default Fetch;
