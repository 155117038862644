import React, { Component } from 'react';
import { Table, Card, Label } from 'semantic-ui-react';

const numberFormat = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });

export class DonationHistory extends Component {
  render() {
    const { texts, donations } = this.props;

    return (
      <Card.Content>
        <h6 className="gray-700">{texts.donationHistory}</h6>
        <Table singleLine>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>{texts.date}</Table.HeaderCell>
              <Table.HeaderCell>{texts.donationAmount}</Table.HeaderCell>
              <Table.HeaderCell>{texts.status}</Table.HeaderCell>
              <Table.HeaderCell>{texts.paymentMethod}</Table.HeaderCell>
              <Table.HeaderCell width={1}></Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {donations.map((donation) => (
              <Table.Row key={donation.id}>
                <Table.Cell>
                  {new Date(donation.createdAt).toLocaleDateString()}
                </Table.Cell>
                <Table.Cell>
                  {numberFormat.format(donation.amount)}
                </Table.Cell>
                <Table.Cell>
                  {donation.status}
                </Table.Cell>
                <Table.Cell>
                  <div className="flex items-center">
                    <img src={donation.imageUrl} height="25" />
                    <div className="fs-1 ml-5">
                      <Label size="small">{donation.last4}</Label>
                    </div>
                  </div>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Card.Content>
    );
  }
}

export default DonationHistory;
