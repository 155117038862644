import React, { PureComponent, Fragment } from 'react';
import { Table, Button } from 'semantic-ui-react';
import Fetch from '../Fetch';
import DonationSubscriptionRow from './DonationSubscriptionRow';
import OneTimeDonationSubscriptionRow from './OneTimeDonationSubscriptionRow';

class DonationSubscriptions extends PureComponent {
  render() {
    const { donationSubscriptions, braintreePaymentMethods, braintreeAuthorization, texts, user } = this.props;

    return (
      <Fetch proxy={donationSubscriptions.includes('donation-plan', 'braintree-payment-method', 'donations')}>
        {(data, reload) => data.toArray().map(donationSubscription => (
          <Fragment key={donationSubscription.id}>
            <h2 className="border-bottom pb-10 mb-15">
              {donationSubscription.billingCycle === 0 ? texts.oneTimeDonation : texts.recurringDonation}
            </h2>
            {donationSubscription.billingCycle === 0 ? (
              <OneTimeDonationSubscriptionRow
                donationSubscription={donationSubscription}
                texts={texts}
                reload={reload}
                user={user}
                braintreeAuthorization={braintreeAuthorization}
              />
            ) : (
              <DonationSubscriptionRow
                donationSubscription={donationSubscription}
                texts={texts}
                reload={reload}
                user={user}
                braintreeAuthorization={braintreeAuthorization}
              />
            )}
          </Fragment>
        ))}
      </Fetch>
    );
  }
}

export default DonationSubscriptions;
