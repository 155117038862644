import React, { PureComponent } from 'react';
import { Table, Button, Card, Label, Image, Dimmer } from 'semantic-ui-react';
import DonationSubscriptionForm from './DonationSubscriptionForm';
import DonationHistory from './DonationHistory';

class DonationSubscriptionRow extends PureComponent {
  state = {
    edit: false
  };

  activateSubscription(donationSubscription) {
    const { reload } = this.props;

    donationSubscription.isActive = true;
    donationSubscription.save().then(() => {
      reload()
    });
  }

  cancelSubscription(donationSubscription) {
    const { texts, reload } = this.props;

    if (window.confirm(texts.cancelConfirm)) {
      donationSubscription.isActive = false;
      donationSubscription.save().then(() => {
        reload();
      });
    }
  }

  render() {
    const { braintreeAuthorization, donationSubscription, texts, reload, user } = this.props;
    const donationPlan = donationSubscription.donationPlan();
    const braintreePaymentMethod = donationSubscription.braintreePaymentMethod();
    const donations = donationSubscription.donations().toArray().sort((a, b) => b.id - a.id);

    return (
      <Card fluid className="box box-border-top">
        <Card.Content>
          <Card.Header className="pt-10 float-left float-none-xs">
            {donationPlan.name}
          </Card.Header>
          <div className='ui float-right float-none-xs mt-15-xs'>
            {donationSubscription.isActive ? (
              <React.Fragment>
                {this.state.edit ? (
                  <Button color="grey" size="small" onClick={() => this.setState({ edit: false })}>
                    {texts.cancel}
                  </Button>
                ) : (
                  <React.Fragment>
                    <Button basic color="green" size="small" onClick={() => this.setState({ edit: true })}>
                      {texts.donate}
                    </Button>
                  </React.Fragment>
                )}
              </React.Fragment>
            ) : null}
          </div>
        </Card.Content>

        <Card.Content className="no-padding">
          <Dimmer.Dimmable dimmed={!donationSubscription.isActive}>
            <DonationSubscriptionForm
              isOneTime={true}
              edit={this.state.edit}
              braintreeAuthorization={braintreeAuthorization}
              user={user}
              texts={texts}
              donationSubscription={donationSubscription}
              braintreePaymentMethod={braintreePaymentMethod}
              reload={reload}
              onExit={() => this.setState({ edit: false })}
            />
            <Dimmer active={!donationSubscription.isActive}>
              <Button color="green" size="small" onClick={() => this.activateSubscription(donationSubscription)}>
                {texts.activate}
              </Button>
            </Dimmer>
          </Dimmer.Dimmable>
        </Card.Content>

        <DonationHistory texts={texts} donations={donations} />
      </Card>
    );
  }
}

export default DonationSubscriptionRow;
