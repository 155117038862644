import React, { Fragment, PureComponent } from 'react';
import { User } from '../../models';
import DonationSubscriptions from './DonationSubscriptions';
import Spinner from '../Spinner';
import SetPassword from './SetPassword';

class Manage extends PureComponent {
  state = {
    user: null
  };

  componentDidMount() {
    this.fetchUser();
  }

  fetchUser() {
    User.includes('braintree-payment-methods').find('me').then((user) => {
      window.user = user;
      this.setState({ user });
    });
  }

  render() {
    const { texts, braintreeAuthorization } = this.props;

    if (!this.state.user) {
      return null;
    }

    return this.state.user.shouldSetPassword ? (
      <SetPassword texts={texts} onSet={() => this.fetchUser()} />
    ) : (
      <Fragment>
        <DonationSubscriptions
          braintreeAuthorization={braintreeAuthorization}
          texts={texts}
          user={this.state.user}
          donationSubscriptions={this.state.user.donationSubscriptions()}
          braintreePaymentMethods={this.state.user.braintreePaymentMethods()}
        />
      </Fragment>
    );
  }
}

export default Manage;
