import React from "react"
import PropTypes from "prop-types"
import { Card } from 'semantic-ui-react'

class Home extends React.Component {
  componentDidMount() {
    braintree.dropin.create({
      authorization: 'sandbox_pgt6yyzq_kgftcfyzdvqwzh8f',
      container: '#dropin-container',
      applePay: {
        displayName: 'Merchant Name',
        paymentRequest: {
        label: 'Localized Name',
          total: '10.00'
        }
      },
      paypal: {
        flow: 'checkout',
        amount: '10.00',
        currency: 'USD'
      },
      venmo: true
    }, function (createErr, instance) {
      button.addEventListener('click', function () {
        instance.requestPaymentMethod(function (err, payload) {
          // Submit payload.nonce to your server
        });
      });
    });
  }

  render () {
    return (
      <div>
        <Card fluid>
          <Card.Content extra id="dropin-container">
          </Card.Content>
        </Card>
      </div>
    );
  }
}

export default Home
