import React, { Component } from 'react';
import { Button, Card } from 'semantic-ui-react'

class DonationAmount extends Component {
  render() {
    const { amount, selectedAmount, onClick } = this.props;

    return (
      <Button
        color={amount === parseInt(selectedAmount) ? "blue" : null}
        className="w-100"
        style={{
          height: 40
        }}
        onClick={onClick}
      >
        ${amount}
      </Button>
    );
  }
}

export default DonationAmount;
