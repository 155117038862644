import React, { Component } from 'react';

const STEPS = [
  "정보"
];

class DonationNav extends Component {
  render() {
    const { texts, totalSteps, goToStep, currentStep } = this.props;
    const steps = [];

    for (let i = 1; i <= totalSteps; i++) {
      steps.push(i);
    }

    return (
      <div className="step-nav">
        {steps.map((step) => (
          <div key={step} className="flex flex-column items-center pointer" onClick={() => {
            if (step === 1) {
              goToStep(step);
            }
          }}>
            <div className={`step-nav-item ${currentStep === step ? "active" : ""} ${step < currentStep ? "done" : ""}`}>
              {step}
            </div>
            <div className="pt-5">
              {texts.steps[step - 1]}
            </div>
          </div>
        ))}
      </div>
    );
  }
}

export default DonationNav;
