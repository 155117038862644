import React, { Fragment } from 'react';
import { Input, Icon, Label } from 'semantic-ui-react'
import DonationAmount from './DonationAmount';

const DonationAmounts = ({ texts, amount, inputAmount, setInformationAmount }) => (
  <Fragment>
    <div className="flex grid">
      <div className="w-20 w-50-xs ph-10 pb-10-xs">
        <DonationAmount
          amount={10}
          selectedAmount={amount}
          onClick={() => setInformationAmount({ amount: 10, inputAmount: '' })}
        />
      </div>
      <div className="w-20 w-50-xs ph-10 pb-10-xs">
        <DonationAmount
          amount={25}
          selectedAmount={amount}
          onClick={() => setInformationAmount({ amount: 25, inputAmount: '' })}
        />
      </div>
      <div className="w-20 w-50-xs ph-10 pb-10-xs">
        <DonationAmount
          amount={50}
          selectedAmount={amount}
          onClick={() => setInformationAmount({ amount: 50, inputAmount: '' })}
        />
      </div>
      <div className="w-20 w-50-xs ph-10 pb-10-xs">
        <DonationAmount
          amount={100}
          selectedAmount={amount}
          onClick={() => setInformationAmount({ amount: 100, inputAmount: '' })}
        />
      </div>
      <div className="w-20 w-100-xs ph-10 pb-10-xs">
        <Input
          labelPosition='left'
          type='number'
          placeholder={texts.otherAmount}
        >
          <Label basic>$</Label>
          <input
            type="number"
            className="w-100"
            style={{flex: "1 0"}}
            value={inputAmount}
            onInput={(e) => setInformationAmount({ amount: e.currentTarget.value, inputAmount: e.currentTarget.value })}
          />
        </Input>
      </div>
    </div>
  </Fragment>
);

export default DonationAmounts;
