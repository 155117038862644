import React, { PureComponent } from 'react';
import { Form, Button } from 'semantic-ui-react';
import Api from '../lib/api';

class SetPassword extends PureComponent {
  setPassword() {
    const { onSet } = this.props;

    Api.patch('/users/set_password', {
      user: {
        password: this.state.password,
        password_confirmation: this.state.passwordConfirmation
      }
    }).then(() => {
      onSet();
    });
  }

  render() {
    const { texts } = this.props;

    return (
      <div class="card">
        <Form>
          <h5>{texts.setPassword}</h5>
          <Form.Field>
            <input type="password" placeholder="Password" onChange={(e) => this.setState({ password: e.currentTarget.value })} />
          </Form.Field>
          <Form.Field>
            <input type="password" placeholder="Password Confirmation" onChange={(e) => this.setState({ passwordConfirmation: e.currentTarget.value })} />
          </Form.Field>
          <Button color="green" className="w-100" onClick={() => this.setPassword()}>
            {texts.save}
          </Button>
        </Form>
      </div>
    );
  }
}

export default SetPassword;
