import Store from './Store';

class User extends Store.Base {
  static className = 'User';
  static define() {
    this.hasMany('braintreePaymentMethods');
    this.hasMany('donationSubscriptions');
    this.attributes('firstName', 'lastName', 'email', 'shouldSetPassword');
  }
}

export default Store.createResource(User);
